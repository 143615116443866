<template>
  <div class="dashboard">

    <!-- Main -->
    <div>
      <v-container style="max-width: 1200px" v-if="user.accessRoles">
        <h1 style="margin-bottom: 10px">Hallo {{ user.prename }}!</h1>
        <v-row>
          <!-- CRM -->
          <dashboard-btn target="CRM" icon="crm" cols="4" v-if="user.accessRoles.crm.default">CRM</dashboard-btn>

          <!-- Fälle eingeben -->
          <dashboard-btn target="Cases" icon="nachweis" cols="4" v-if="user.accessRoles.cases.default">Fälle eingeben</dashboard-btn>

          <!-- Finanzen -->
          <dashboard-btn target="Finance" icon="zahlung" cols="4" v-if="user.accessRoles.finance.default">Finanzen</dashboard-btn>

          <!-- Settings -->
          <dashboard-btn target="Settings" icon="schloss" cols="4" v-if="user.accessRoles.settings">Einstellungen</dashboard-btn>

          <!-- Statistics -->
          <dashboard-btn target="Statistics" icon="statistics" cols="4" v-if="user.accessRoles.statistics.default">Statistiken</dashboard-btn>

          <!-- Verification -->
          <dashboard-btn target="Verification" icon="pacman" cols="4" v-if="user.accessRoles.verification">Verification</dashboard-btn>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import DashboardBtn from '@/components/dashboard/DashboardBtn.vue'
export default {
  computed: {
    user () {
      return this.$store.getters.user
    },
    admin () {
      return this.$store.getters.admin
    },
    investor () {
      return this.$store.getters.investor
    }
  },
  components: {
    dashboardBtn: DashboardBtn
  },
  mounted () {
    axios.get('/user')
      .then(res => {
        var data = res.data
        this.$store.commit('setUser', data)
      })
      .catch(() => {})
  }
}
</script>

<style scoped>
  * >>> .v-responsive__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center
  }

  .dashboard-btn {
    width: 100%;
    border-radius: 10px !important;
    box-shadow: 0px 0px 15px #3f3f3f2e !important
  }
</style>
